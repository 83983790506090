.plusandminus {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 10px;
    height: 30px;
}
.item_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid;
    border-radius: 10px;
    padding: 30px 20px;
    height: 100px;
}
.summary_list_items {
    max-height: 250px;
    overflow-y: scroll;
}
.summary_list_items::-webkit-scrollbar {
    display: none;
}
.item_start {
    display: flex;
    align-items: center;
    gap: 15px;
}
.list_info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.summary_card {
    display: flex;
}
.quantity {
    /* color: #000; */
    font-size: 20px;
}
.summarycard {
    position: fixed;
    bottom: 40px;
    width: 100%;
    height: 150px;
    overflow-y: scroll;
}
.summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
}
.summary_total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
}
.stcky_summary {
    position: sticky;
    top: 140px;
    overflow-y: scroll;
    max-height: 300px;
}
.payment_view {
    max-height: 60vh;
    overflow-y: scroll;
}
.stcky_summary::-webkit-scrollbar, .payment_view::-webkit-scrollbar {
    display: none;
}
@media (max-width: 768px) {
    .stcky_summary {
        position: sticky;
        top: unset;
        bottom: 60px;
        max-height: 200px;
    }
    .payment_view {
        max-height: 90vh;
        overflow-y: scroll;
    }
}
.lowerdiv {
    display: flex;
    align-items: center;
    gap: 4px;
}
.button {
    height: 40px;
}
